const ServiceWorker = {
    register: () => {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', () => {
                navigator.serviceWorker.register('/service-worker.js')
                    .then((registration) => {
                        // Customize PWA installation options
                        registration?.update(); // Ensure service worker is up-to-date
                        registration?.waiting?.postMessage({ type: 'SKIP_WAITING' }); // Skip waiting
                        registration?.installing?.addEventListener('statechange', (event) => {
                            if (event.target.state === 'installed') {
                                // Notify the user about the new version and offer to update
                            }
                        });
                    })
                    .catch((error) => {
                        console.log('ServiceWorker registration failed:', error);
                    });
            });
        }
    },
    deRegister:()=>{
        console.log("Service Worker will Not Work");
    }
}
export default ServiceWorker;