import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    activeSceneId: "",
    activeSceneData: {},
    nextSceneData: {},
    pendingRequestData: [],
    tempUserRole: {}
};


export const broadCastSlice = createSlice({
    name: 'broadCast',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {

        setActiveSceneId: (state, action) => {
            const { payload } = action;
            state.activeSceneId = payload;
        },

        setActiveSceneData: (state, action) => {
            const { payload } = action;
            state.activeSceneData = {
                data: payload,
                id: payload.id
            };
        },
        setNextSceneData: (state, action) => {
            const { payload } = action;
            if (payload) {
                state.nextSceneData = {
                    data: payload.data,
                    id: payload.id
                };
            }else {
                state.nextSceneData = {};
            }

        },
        setPendingData: (state, { payload }) => {
            state.pendingRequestData = payload;
        },
        setTempUserRole: (state, { payload }) => {
            state.tempUserRole = payload;
        }
    },
});

export const { setActiveSceneId, setActiveSceneData, setPendingData, setTempUserRole, setNextSceneData } = broadCastSlice.actions;

export default broadCastSlice.reducer;
