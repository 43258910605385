import React from "react";
import webinarLogo from '../../Assets/images/webinarLogo.png';
import Svg from "../../Assets/Svg/Svg";
//import CurveLine from "../../images/curve_line.png";
import CurveLine1 from "../../images/CurveLine1.png";
import CurveLine2 from "../../images/CurveLine2.png";

const FourOFour = ({ title = "404", msg = "Oops! Page Not Found", errMsg = "Something went wrong. The page that you are looking for is missing" }) => {
    return (
        <div className="container-error">
            <div className="CurveLine CurveLine1">
                <img loading="lazy" src={CurveLine1} className="maxImg" />
            </div>
            <div className="ErrorInbox">
                <div className="LogoWebinar">
                    <img loading="lazy" src={webinarLogo} className="maxImg" />
                </div>
                <div className="PlugWebinar">

                    {Svg().PlugIcon}

                </div>
                <div className="ErrorInfo">
                    <h3>{title}</h3>
                    <strong>{msg}</strong>
                    <p>{errMsg}</p>
                </div>
            </div>
            <div className="CurveLine CurveLine2">
                <img loading="lazy" src={CurveLine2} className="maxImg" />
            </div>
        </div>
    )
}
export default FourOFour;