import { useEffect } from 'react';
import { getAuth, signInAnonymously, updateProfile, onAuthStateChanged } from "firebase/auth";
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { validateToken, validateTokenEmbedRegistration, logout } from '../redux/actions';

function AuthInitBase({
  history
}) {

  const {
    auth: { authToken, userAlreadyLoggedIn, tokenValidated, userTokenInvalid, isEmbeddedRegistration }, event: { eventData }
  } = useSelector(({ auth, event }) => { return { auth, event } });

  const eventId = eventData?.uid;

  const dispatch = useDispatch();

  useEffect(() => {
    if (userAlreadyLoggedIn && authToken && !tokenValidated && eventId && !isEmbeddedRegistration) {
      dispatch(validateToken(eventId));
    }
  }, [eventId, userAlreadyLoggedIn, tokenValidated, authToken])

  useEffect(() => {
    if (userAlreadyLoggedIn && authToken && !tokenValidated && eventId && isEmbeddedRegistration) {
      dispatch(validateTokenEmbedRegistration(eventId));
    }
  }, [eventId, userAlreadyLoggedIn, tokenValidated, authToken])

  useEffect(() => {
    if (tokenValidated) {
      const auth = getAuth();

      signInAnonymously(auth)
        .then((user) => { })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });

      onAuthStateChanged(auth, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          if (eventId) {
            updateProfile(user, {
              displayName: eventId
            }).then(() => {
            })
          }
        } else {
          // User is signed out
          // ...
        }
      });
    }
  }, [tokenValidated])

  useEffect(() => {
    if (userTokenInvalid === false) {
      dispatch(logout());
      history.push(`${eventId}/registration`);
    }
  }, [userTokenInvalid]);

  return null;
}

export const AuthInit = withRouter(AuthInitBase);