import {useState, useEffect} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from '../redux/actions'
import store from '../redux/store';

const LocationListenerBase = (props) => {

const [currentLocation, setCurrentLocation] = useState(null)
    useEffect(() => {
        props.history.listen((location, action) => {
            const { listeners } = store.getState();

            setCurrentLocation((prv) => {
                if(prv !== location.pathname) {
                    if (listeners.list && listeners.list.length > 0) {
                        listeners.list.forEach((value, index) => {
                            if( typeof value === 'function') {
                                value();
                            }
                            
                            if(index === listeners.list.length -1){
                                props.removeAllListeners(() => {
                                    // console.log("Listener Removed");
                                })
                            }
                        })

                    }
                }
                return location.pathname;
            })
        })

    },[props.eventId])
    return null
}

const mapStateToProps = ({ listeners, event }) => {
    return {
        listeners: [...listeners.list].filter(c => c!== null),
        currentLocation: listeners.currentLocation,
        event: event.eventData?.uid

    }
}

export const LocationListener = connect(mapStateToProps, actions)(withRouter(LocationListenerBase))