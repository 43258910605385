import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionTypes } from '../actionTypes';

const initialState = {
    list: [],
    isLive:false,
}

export default persistReducer(
    { storage, key: "webinar-events-schedule", whitelist: ["scheduleList"] },
    (state = initialState, action) => {
        const { type, payload, dates, stages } = action;
        switch (type) {
            case actionTypes.GET_SCHEDULE_DETAILS_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.GET_SCHEDULE_DETAILS_SUCCESS: {
                return { ...state, list: payload, loading: false, dates, stages };
            }
            case actionTypes.GET_SCHEDULE_DETAILS_ERROR: {
                return { ...state, loading: false };
            }
            case actionTypes.GET_SCHEDULE_LIST_THEME_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.GET_SCHEDULE_LIST_THEME_SUCCESS: {
                return { ...state, list: payload, loading: false, dates, stages };
            }
            case actionTypes.GET_SCHEDULE_LIST_THEME_ERROR: {
                return { ...state, loading: false };
            }
            case actionTypes.GET_LIVE_SCHEDULE: {
                return { ...state, isLive: payload };
            }
            default:
                return state;
        }
    })
