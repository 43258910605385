import { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const timeOut = 500;

const RegistrationPage = lazy(() => import("../pages/Registration"));
const DirectLogin = lazy(() => import("../pages/DirectLogin"));
const StageRecording = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/StageRecording")), timeOut) }));
const EventExpired = lazy(() => import("../pages/EventExpired"));
const EmbedRegistrationPage = lazy(() => import("../components/NewRegistration/NewRegistrationPage"));
const LandingPage1 = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/LandingPage1")), timeOut) }));
const LandingPage2 = lazy(() => new Promise(resolve => { setTimeout(() => resolve(import("../pages/LandingPage2")), timeOut) }));

const AuthRoute = [
    { path: "registration/:code?", component: RegistrationPage },
    { path: "embedded-registration/:code?", component: EmbedRegistrationPage },
    { path: "stages/:stageId/:sessionId/stageRecording", component: StageRecording },
    { path: "event-expired", component: EventExpired },
    { path: "direct-login", component: DirectLogin },
];

const landingRoute = [
    { path: ":oid/landingPage", component: LandingPage1 },
    { path: ":oid/landingPage2", component: LandingPage2 }
]

const AuthPage = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {landingRoute?.map(({ path, component }) => {
                    return (
                        <Route key={path} path={`/${path}`} component={component} />
                    )
                })}
                {AuthRoute?.map(({ path, component }) => {
                    return (
                        <Route key={path} path={`/:id/${path}`} component={component} />
                    )
                })}
                <Redirect path="/:id" to="/:id/registration/:searcValue?" />
            </Switch>
        </Suspense>
    );
}

export default AuthPage;
