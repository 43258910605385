import axios from 'axios';
import * as constants from '../../constants';
import { findDeviceType, showToast } from '../../utils';
import { actionTypes } from '../actionTypes';
import { validateToken } from "./auth";
import { VALIDATE_TOKEN } from "../../constants";

const { getUrl, getUrlEmbed } = constants;

export const saveEventId = (eventId) => dispatch => {
    dispatch({
        type: actionTypes.SAVE_EVENT_ID,
        payload: eventId
    })
}

// get event basic details
export const getEventDetails = (eventId, cb = null) => async (dispatch) => {
    const { GET_EVENT_DETAILS } = constants;

    dispatch({
        type: actionTypes.GET_EVENT_DETAILS_INITIATED
    });

    try {
        dispatch({
            type: actionTypes.TOKEN_VALIDATION_INITIATED
        })
        axios.get(getUrl(VALIDATE_TOKEN, eventId)).then(async (res) => {
            dispatch({
                type: actionTypes.TOKEN_VALIDATION_SUCCESS,
                payload: res.data.data
            })
            const eventRes = await axios.get(getUrl(GET_EVENT_DETAILS, eventId));
            dispatch({
                type: actionTypes.GET_EVENT_DETAILS_SUCCESS,
                payload: eventRes.data.data
            });
            if (cb) cb(eventRes?.data);
            if (res.data?.data?.registration_complete === 1) {
                dispatch({
                    type: actionTypes.COMPLETE_REGISTRATION,
                })
            } else {
                dispatch({
                    type: actionTypes.RESET_EVENT_REGISTRATION_STATUS,
                })
            }
        }, async () => {
            const eventRes = await axios.get(getUrl(GET_EVENT_DETAILS, eventId));
            dispatch({
                type: actionTypes.GET_EVENT_DETAILS_SUCCESS,
                payload: eventRes.data.data
            });
            if (cb) cb(eventRes?.data);
        }).catch(async (err) => {
            cb(err?.response);
            /*const eventRes = await axios.get(getUrl(GET_EVENT_DETAILS, eventId));
            dispatch({
                type: actionTypes.GET_EVENT_DETAILS_SUCCESS,
                payload: eventRes.data.data
            });*/
        });


        /*validateToken(eventId, async () => {
            const res = await axios.get(getUrl(GET_EVENT_DETAILS, eventId));
            dispatch({
                type: actionTypes.GET_EVENT_DETAILS_SUCCESS,
                payload: res.data.data
            });
        });*/
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EVENT_DETAILS_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

/**
 * @method: getExpiredEventDetails
 * @param {*} eventId 
 * @returns 
 */
export const getExpiredEventDetails = (eventId) => async (dispatch) => {
    const { GET_EXPIRED_EVENT_DETAILS } = constants;

    dispatch({
        type: actionTypes.GET_EXPIRED_EVENT_DETAILS_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_EXPIRED_EVENT_DETAILS, eventId));
        dispatch({
            type: actionTypes.GET_EXPIRED_EVENT_DETAILS_SUCCESS,
            payload: res.data.data
        });
        return res.data.data;
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EXPIRED_EVENT_DETAILS_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

//get event registration form fields
export const getEventRegistrationDetails = (eventId) => async (dispatch) => {
    const { GET_EVENT_REGISTRATION_DETAILS } = constants;

    dispatch({
        type: actionTypes.GET_EVENT_REGISTRATION_FIELDS_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_EVENT_REGISTRATION_DETAILS, eventId));
        dispatch({
            type: actionTypes.GET_EVENT_REGISTRATION_FIELDS_SUCCESS,
            payload: res.data.data
        });
        return res.data.data;
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EVENT_REGISTRATION_FIELDS_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

// function onEventRegistrationFieldChanged
export const onEventRegistrationFieldChanged = (data) => dispatch => {
    dispatch({
        type: actionTypes.EVENT_REGISTRATION_FIELD_CHANGED,
        payload: data
    });
}

// function onEventRegistrationDisclaimerChanged
export const onEventRegistrationDisclaimerChanged = (data) => dispatch => {
    dispatch({
        type: actionTypes.EVENT_REGISTRATION_DISCLAIMER_CHANGED,
        payload: data
    });
}

// function to submit event registration form
export const onEventRegistrationFormSubmit = (eventId, data, cb = null) => async (dispatch) => {
    const { SUBMIT_EVENT_REGISTRATION_FORM } = constants;
    dispatch({
        type: actionTypes.EVENT_FORM_SUBMIT_INITIATED
    });
    data.login_device = findDeviceType();
    try {
        const res = await axios.post(getUrl(SUBMIT_EVENT_REGISTRATION_FORM, eventId), data);
        // dispatch({
        //     type: actionTypes.EVENT_FORM_SUBMIT_SUCCESS,
        //     payload: res.data
        // });
        dispatch({
            type: actionTypes.ON_EVENT_REGISTRAION_SUCCESS,
            payload: res.data.data
        });
        dispatch({
            type: actionTypes.EVENT_FORM_SUBMIT_SUCCESS,
            payload: res.data.data
        });

        if (cb) {
            cb();
        }
        showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.EVENT_FORM_SUBMIT_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || "some error occurred" });
    }
}

// function to verify speaker invitation token
// export const onEventSpeakerTokenVerify = (eventId, tokenData) => async (dispatch) => {
//     const { VERIFY_SPEAKER_TOKEN } = constants;

//     dispatch({
//         type: actionTypes.VERIFY_SPEAKER_TOKEN_INITIATED
//     });
//     try {
//         const res = await axios.post(getUrl(VERIFY_SPEAKER_TOKEN, eventId), tokenData);
//         // dispatch({
//         //     type: actionTypes.EVENT_FORM_SUBMIT_SUCCESS,
//         //     payload: res.tokenData
//         // });
//         dispatch({
//             type: actionTypes.ON_VERIFY_SPEAKER_TOKEN_SUCCESS,
//             payload: res.data.data
//         });
//         dispatch({
//             type: actionTypes.VERIFY_SPEAKER_TOKEN_SUCCESS,
//             payload: res.data.data
//         });

//         // showToast({variant: 'success', message: res.data.message});
//     } catch (error) {
//         dispatch({
//             type: actionTypes.VERIFY_SPEAKER_TOKEN_ERROR
//         });
//         showToast({variant: 'error', message: error?.response?.data?.message || "some error occurred"});
//     }
// }

// function to submit event registration form
export const completeRegistration = () => (dispatch) => {

    dispatch({
        type: actionTypes.COMPLETE_REGISTRATION
    });

}

export const resetEventRegistrationStatus = () => (dispatch) => {
    dispatch({
        type: actionTypes.RESET_EVENT_REGISTRATION_STATUS
    })
}

export const getEventPeopleList = (eventId, searchTerm) => (dispatch) => {
    const { GET_PEOPLE_LIST } = constants;

    try {
        axios.get(`${getUrl(GET_PEOPLE_LIST, eventId)}?search=${searchTerm}`).then(res => {
            dispatch({
                type: actionTypes.GET_PEOPLE_LIST,
                payload: res.data.data
            })
        })
    } catch (error) {
        // dispatch({
        //     type: actionTypes.EVENT_FORM_SUBMIT_ERROR
        // });
        // showToast({variant: 'error', message: error?.response?.data?.message || "some error occurred"});       
    }
}

export const getEventSpeakersList = (eventId) => (dispatch) => {
    const { GET_SPEAKERS_LIST } = constants;

    try {
        axios.get(getUrl(GET_SPEAKERS_LIST, eventId)).then(res => {
            dispatch({
                type: actionTypes.GET_SPEAKERS_LIST,
                payload: res.data.data
            })
        })
    } catch (error) {
        // dispatch({
        //     type: actionTypes.EVENT_FORM_SUBMIT_ERROR
        // });
        // showToast({variant: 'error', message: error?.response?.data?.message || "some error occurred"});       
    }
}

export const getNotificationItem = (data, type) => dispatch => {
    dispatch({
        type: actionTypes.GET_NOTIFICATION_LIST_ITEM,
        payload: { ...data, category: type }
    });
}

export const setZonesWithStatus = (data) => dispatch => {
    dispatch({
        type: actionTypes.SET_ZONES_WITH_STATUS,
        payload: data
    });
}

// EMBED registration APIs

//get event registration form fields
export const getEmbedRegistrationDetails = (eventId) => async (dispatch) => {
    const { EMBEDDED_EVENT_REGISTRATION_DETAILS } = constants;

    dispatch({
        type: actionTypes.EMBED_EVENT_REGISTRATION_FIELDS_INITIATED
    });

    try {
        const res = await axios.get(getUrlEmbed(EMBEDDED_EVENT_REGISTRATION_DETAILS, eventId));
        dispatch({
            type: actionTypes.EMBED_EVENT_REGISTRATION_FIELDS_SUCCESS,
            payload: res.data.data
        });
        return res.data.data;
    } catch (error) {
        dispatch({
            type: actionTypes.EMBED_EVENT_REGISTRATION_FIELDS_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

// function to submit event registration form
export const onEmbedRegistrationFormSubmit = (eventId, data, cb = null) => async (dispatch) => {
    const { SUBMIT_EMBEDDED_REGISTRATION_FORM } = constants;

    dispatch({
        type: actionTypes.EMBED_EVENT_FORM_SUBMIT_INITIATED
    });
    try {
        const res = await axios.post(getUrlEmbed(SUBMIT_EMBEDDED_REGISTRATION_FORM, eventId), data);
        // dispatch({
        //     type: actionTypes.EVENT_FORM_SUBMIT_SUCCESS,
        //     payload: res.data
        // });
        dispatch({
            type: actionTypes.ON_EMBED_REGISTRATION_SUCCESS,
            payload: res.data.data
        });
        dispatch({
            type: actionTypes.EMBED_EVENT_FORM_SUBMIT_SUCCESS,
            payload: res.data.data
        });

        if (cb) {
            cb();
        }
        showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.EMBED_EVENT_FORM_SUBMIT_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || "some error occurred" });
    }
}