import axios from 'axios';
import * as constants from '../../constants';
import { showToast } from "../../utils";
import { actionTypes } from '../actionTypes';
const { getUrl } = constants;

export const getScheduleDetails = (eventId) => async (dispatch) => {
    const { GET_SCHEDULE_DETAILS } = constants;
    dispatch({
        type: actionTypes.GET_SCHEDULE_DETAILS_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_SCHEDULE_DETAILS, eventId));
        dispatch({
            type: actionTypes.GET_SCHEDULE_DETAILS_SUCCESS,
            payload: res.data.schedules,
            dates: res.data.dates,
            stages: res.data.stages
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_STAGE_DETAILS_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getScheduleListForTheme = (eventId) => async (dispatch) => {
    const { GET_SCHEDULE_LIST_FOR_THEME } = constants;
    dispatch({
        type: actionTypes.GET_SCHEDULE_LIST_THEME_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_SCHEDULE_LIST_FOR_THEME, eventId));
        dispatch({
            type: actionTypes.GET_SCHEDULE_LIST_THEME_SUCCESS,
            payload: res.data.schedules,
            dates: res.data.dates,
            stages: res.data.stages

        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_SCHEDULE_LIST_THEME_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}


export const getLiveSchedule = (data) => async (dispatch) => {
    dispatch({
        type: actionTypes.GET_LIVE_SCHEDULE, payload: data
    });
}
