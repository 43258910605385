import { getQnaListWithListener, hasUserRespondedToUpVote, saveQnaDownVotes, saveQnaUpVotes, saveQuestion } from "../../utils/firebase";
import { showToast } from '../../utils';
import { actionTypes } from "../actionTypes";

export const getQuestionsListWithListenerAction = (path, cb) => async (dispatch) => {
    if (path) {
        const saveQuestionList = (data) => {
            dispatch({
                type: actionTypes.GET_QUESTIONS_LIST,
                path,
                data
            });
            if (cb)
                cb(data)
        }
        getQnaListWithListener(path, saveQuestionList)
    }
}

export const saveQuestionAction = (path, qna, profileImage, id, from, callback) => (dispatch) => {
    try {
        saveQuestion(path, qna, profileImage, id, from, callback);
    } catch (error) {
        showToast({ 'variant': 'error', 'message': 'Some error occurred' });
    }
}

export const saveQnaUpVotesAction = (qnaPath, qnaResponsePath, userId, qna, activeScenePath) => (dispatch) => {
    try {
        saveQnaUpVotes(qnaPath, qnaResponsePath, userId, qna, activeScenePath, () => {
            localStorage.setItem(`${qnaPath}/${qna.id}/${userId}`, true);
        });
        dispatch({
            type: actionTypes.SAVE_USER_RESPONDED_QNA,
            payload: { qnaId: qna.id, value: true }
        })
    } catch (error) {

    }
}

export const saveQnaDownVotesAction = (qnaPath, qnaResponsePath, userId, qna, activeScenePath) => (dispatch) => {
    try {
        saveQnaDownVotes(qnaPath, qnaResponsePath, userId, qna, activeScenePath, () => {
            localStorage.setItem(`${qnaPath}/${qna.id}/${userId}`, false);
        });
        dispatch({
            type: actionTypes.SAVE_USER_RESPONDED_QNA,
            payload: { qnaId: qna.id, value: true }
        })
    } catch (error) {

    }
}

export const hasUserRespondedUpVoteAction = (qnaResponsePath, userId, qnaId) => async dispatch => {
    const res = await hasUserRespondedToUpVote(`${qnaResponsePath}/${userId}/${qnaId}`);
    dispatch({
        type: actionTypes.SAVE_USER_RESPONDED_QNA,
        payload: { qnaId, value: res }
    })
}

export const hideQnANotification = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.HIDE_QNA_NOTIFICATION,
        payload: value
    })
}

export const addQnaModuleId = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.ADD_MODULE_ID,
        payload: value
    })
}
