const Svg = () => {
  return {
    Loader: (
      <svg className="splash-spinner" viewBox="0 0 50 50">
        <circle
          className="path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          strokeWidth="5"
        ></circle>
      </svg>
    ),

    
    PlugIcon: <svg xmlns="http://www.w3.org/2000/svg" width="853.217" height="161.882" viewBox="0 0 853.217 161.882">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_4344" data-name="Rectangle 4344" width="853.217" height="161.882" transform="translate(54 -31)" fill="none"/>
        </clipPath>
        <clipPath id="clip-path-2">
          <rect id="Rectangle_4316" data-name="Rectangle 4316" width="853.216" height="407.882" transform="translate(54)" fill="none"/>
        </clipPath>
      </defs>
      <g id="graphic" transform="translate(-54 31)" clip-path="url(#clip-path)">
        <g id="Group_2237" data-name="Group 2237" transform="translate(0 -27)">
          <g id="Group_2236" data-name="Group 2236" clip-path="url(#clip-path-2)">
            <path id="Path_9828" data-name="Path 9828" d="M548.564,91.613a397.709,397.709,0,0,1,56.042-9.6,452.037,452.037,0,0,1,45.55-2.287A437.138,437.138,0,0,1,764.21,94.561c37.7,10.19,70.464,15.357,97.39,15.357,37.263,0,53.9-9.871,60.088-15.1h21.528V80.086H918.764L915.2,80l-2.163,2.761c-.545.569-7.2,7.118-26.048,10.453a145.955,145.955,0,0,1-25.148,1.966c-25.8,0-57.358-4.994-93.792-14.843a451.96,451.96,0,0,0-117.9-15.346,465.639,465.639,0,0,0-47.034,2.364,416.959,416.959,0,0,0-54.551,9.065Z" fill="#272727"/>
            <path id="Path_9829" data-name="Path 9829" d="M548.564,87.814a402.732,402.732,0,0,1,55.671-9.465c37.81-3.851,96.425-4.782,160.934,12.657,113.589,30.705,148.288,6.557,155.121.126h19.244V83.768h-20.81l-1.761-.039-1.1,1.411c-.067.078-6.775,7.906-28.229,11.7-20.026,3.544-57.2,4.175-120.541-12.948a449.514,449.514,0,0,0-116.94-15.219,462.294,462.294,0,0,0-46.662,2.344,411.239,411.239,0,0,0-54.923,9.189Z" fill="#fff"/>
            <path id="Path_9830" data-name="Path 9830" d="M765.169,91.006c113.589,30.705,148.287,6.557,155.12.126h19.244v-2.3H920.289c-6.833,6.431-41.531,30.577-155.12-.127C700.659,71.265,642.045,72.2,604.235,76.046a402.635,402.635,0,0,0-55.671,9.467v2.3a402.7,402.7,0,0,1,55.671-9.465c37.81-3.851,96.424-4.782,160.934,12.657" fill="#bcbec0"/>
            <path id="Path_9831" data-name="Path 9831" d="M394.653,91.613a397.644,397.644,0,0,0-56.04-9.6,452.1,452.1,0,0,0-45.553-2.287A437.1,437.1,0,0,0,179.011,94.561c-37.7,10.19-70.469,15.357-97.393,15.357-37.263,0-53.9-9.871-60.088-15.1H0V80.086H24.451L28.016,80l2.162,2.761c.545.569,7.2,7.118,26.049,10.453a145.947,145.947,0,0,0,25.146,1.966c25.8,0,57.36-4.994,93.793-14.843A451.977,451.977,0,0,1,293.072,64.994a465.678,465.678,0,0,1,47.033,2.364,416.957,416.957,0,0,1,54.548,9.065Z" fill="#272727"/>
            <path id="Path_9832" data-name="Path 9832" d="M394.653,87.814a402.65,402.65,0,0,0-55.667-9.465c-37.814-3.851-96.43-4.782-160.937,12.657-113.588,30.705-148.288,6.557-155.12.126H3.683V83.768H24.492l1.762-.039,1.107,1.411c.065.078,6.773,7.906,28.225,11.7,20.029,3.544,57.2,4.175,120.542-12.948A449.516,449.516,0,0,1,293.072,68.677a462.246,462.246,0,0,1,46.659,2.344,411.19,411.19,0,0,1,54.922,9.189Z" fill="#fff"/>
            <path id="Path_9833" data-name="Path 9833" d="M178.049,91.006c-113.588,30.705-148.288,6.557-155.12.126H3.683v-2.3H22.929c6.832,6.431,41.532,30.577,155.12-.127C242.556,71.265,301.172,72.2,338.986,76.046a402.582,402.582,0,0,1,55.667,9.467v2.3a402.65,402.65,0,0,0-55.667-9.465c-37.814-3.851-96.43-4.782-160.937,12.657" fill="#bcbec0"/>
            <path id="Path_9834" data-name="Path 9834" d="M508.019,95.01a3.854,3.854,0,0,0-3.855-3.854H472a3.852,3.852,0,0,0,0,7.7h32.162a3.853,3.853,0,0,0,3.855-3.849" fill="#bcbec0"/>
            <path id="Path_9835" data-name="Path 9835" d="M472,95.01h32.163a3.834,3.834,0,0,0,3.317-1.926,3.789,3.789,0,0,1,.537,1.926,3.852,3.852,0,0,1-3.854,3.849H472a3.849,3.849,0,0,1-3.85-3.849,3.8,3.8,0,0,1,.534-1.926A3.832,3.832,0,0,0,472,95.01" fill="#f1f2f2"/>
            <path id="Path_9836" data-name="Path 9836" d="M509.871,95.01a5.712,5.712,0,0,1-5.706,5.7H472a5.7,5.7,0,0,1,0-11.409h32.163a5.711,5.711,0,0,1,5.706,5.705m-5.706,2a2,2,0,0,0,0-4H472a2,2,0,0,0,0,4Z" fill="#272727"/>
            <path id="Path_9837" data-name="Path 9837" d="M508.019,60.568a3.855,3.855,0,0,0-3.855-3.852H472a3.851,3.851,0,0,0,0,7.7h32.162a3.851,3.851,0,0,0,3.855-3.85" fill="#bcbec0"/>
            <path id="Path_9838" data-name="Path 9838" d="M472,60.568h32.163a3.835,3.835,0,0,0,3.317-1.925,3.8,3.8,0,0,1,.537,1.925,3.85,3.85,0,0,1-3.854,3.85H472a3.847,3.847,0,0,1-3.85-3.85,3.807,3.807,0,0,1,.534-1.925A3.834,3.834,0,0,0,472,60.568" fill="#f1f2f2"/>
            <path id="Path_9839" data-name="Path 9839" d="M509.871,60.568a5.711,5.711,0,0,1-5.706,5.705H472a5.7,5.7,0,0,1,0-11.409h32.163a5.71,5.71,0,0,1,5.706,5.7m-5.706,2a2,2,0,0,0,0-3.993H472a2,2,0,0,0,0,3.993Z" fill="#272727"/>
            <path id="Path_9840" data-name="Path 9840" d="M575.232,85.923H544.186V69.655h31.046a7.333,7.333,0,0,1,7.335,7.333v1.6a7.334,7.334,0,0,1-7.335,7.334" fill="#f1f2f2"/>
            <path id="Path_9841" data-name="Path 9841" d="M575.232,85.923H544.186V80.942h27.476A7.333,7.333,0,0,0,579,73.608v-1.6a7.36,7.36,0,0,0-.134-1.386,7.324,7.324,0,0,1,3.7,6.367v1.6a7.334,7.334,0,0,1-7.335,7.334" fill="#bcbec0"/>
            <path id="Path_9842" data-name="Path 9842" d="M584.42,76.987v1.6a9.2,9.2,0,0,1-9.188,9.187H544.187a1.855,1.855,0,0,1-1.854-1.854V69.655a1.853,1.853,0,0,1,1.854-1.854h31.045a9.2,9.2,0,0,1,9.188,9.186m-38.379,7.081h29.191a5.488,5.488,0,0,0,5.482-5.479v-1.6a5.487,5.487,0,0,0-5.482-5.479H546.041Z" fill="#272727"/>
            <path id="Path_9843" data-name="Path 9843" d="M530.687,111.236H499.025a7.656,7.656,0,0,1-7.659-7.659V52a7.658,7.658,0,0,1,7.659-7.661h31.662Z" fill="#f1f2f2"/>
            <rect id="Rectangle_4312" data-name="Rectangle 4312" width="11.124" height="66.897" transform="translate(520.908 44.339)" fill="#bcbec0"/>
            <path id="Path_9844" data-name="Path 9844" d="M532.541,44.339v66.9a1.855,1.855,0,0,1-1.854,1.854H499.025a9.524,9.524,0,0,1-9.512-9.513V52a9.522,9.522,0,0,1,9.512-9.513h31.662a1.853,1.853,0,0,1,1.854,1.852m-3.71,65.043V46.193H499.025A5.811,5.811,0,0,0,493.219,52v51.577a5.812,5.812,0,0,0,5.806,5.805Z" fill="#272727"/>
            <path id="Path_9845" data-name="Path 9845" d="M537.634,47.942a5.593,5.593,0,1,0-11.166,0v59.693a5.593,5.593,0,1,0,11.166,0Z" fill="#f1f2f2"/>
            <path id="Path_9846" data-name="Path 9846" d="M532.053,113.557a5.331,5.331,0,0,1-2.8-.8,5.983,5.983,0,0,0,2.793-5.125V47.942a5.984,5.984,0,0,0-2.793-5.124,5.3,5.3,0,0,1,2.8-.8,5.762,5.762,0,0,1,5.581,5.923v59.693a5.76,5.76,0,0,1-5.581,5.922" fill="#bcbec0"/>
            <path id="Path_9847" data-name="Path 9847" d="M539.487,47.941v59.693a7.444,7.444,0,1,1-14.873,0V47.941a7.444,7.444,0,1,1,14.873,0m-3.707,59.693V47.941a3.742,3.742,0,1,0-7.456,0v59.693a3.743,3.743,0,1,0,7.456,0" fill="#272727"/>
            <path id="Path_9848" data-name="Path 9848" d="M548.654,98.391h-11.02V57.185h11.02a6.059,6.059,0,0,1,6.061,6.057V92.331a6.059,6.059,0,0,1-6.061,6.06" fill="#f1f2f2"/>
            <path id="Path_9849" data-name="Path 9849" d="M548.654,98.391h-5.1a6.062,6.062,0,0,0,6.064-6.06V63.242a6.061,6.061,0,0,0-6.064-6.057h5.1a6.059,6.059,0,0,1,6.061,6.057V92.331a6.059,6.059,0,0,1-6.061,6.06" fill="#bcbec0"/>
            <path id="Path_9850" data-name="Path 9850" d="M556.569,63.242V92.331a7.926,7.926,0,0,1-7.915,7.915h-11.02a1.853,1.853,0,0,1-1.854-1.855V57.185a1.854,1.854,0,0,1,1.854-1.854h11.02a7.923,7.923,0,0,1,7.915,7.911m-17.082,33.3h9.167a4.214,4.214,0,0,0,4.209-4.207V63.242a4.212,4.212,0,0,0-4.209-4.2h-9.167Z" fill="#272727"/>
            <rect id="Rectangle_4313" data-name="Rectangle 4313" width="13.107" height="8.068" transform="translate(491.365 73.755)" fill="#bcbec0"/>
            <rect id="Rectangle_4314" data-name="Rectangle 4314" width="13.107" height="4.033" transform="translate(491.366 77.789)" fill="#f1f2f2"/>
            <path id="Path_9851" data-name="Path 9851" d="M506.328,73.755v8.067a1.853,1.853,0,0,1-1.856,1.853H491.366a1.852,1.852,0,0,1-1.854-1.853V73.755a1.853,1.853,0,0,1,1.854-1.855h13.106a1.855,1.855,0,0,1,1.856,1.855m-13.11,6.215h9.4V75.606h-9.4Z" fill="#272727"/>
            <line id="Line_213" data-name="Line 213" y1="24.882" transform="translate(545.523 65.348)" fill="#fff"/>
            <path id="Path_9852" data-name="Path 9852" d="M545.523,63.494a1.854,1.854,0,0,0-1.854,1.853V90.23a1.853,1.853,0,0,0,3.707,0V65.347a1.853,1.853,0,0,0-1.853-1.853" fill="#272727"/>
            <path id="Path_9853" data-name="Path 9853" d="M446.418,123.954,432.05,138.322a1.854,1.854,0,0,0,1.31,3.164,1.835,1.835,0,0,0,1.311-.544l14.371-14.367a1.854,1.854,0,1,0-2.624-2.621" fill="#272727"/>
            <path id="Path_9854" data-name="Path 9854" d="M468.474,131.547a1.853,1.853,0,0,0-1.853,1.855v20.32a1.854,1.854,0,1,0,3.707,0V133.4a1.855,1.855,0,0,0-1.854-1.855" fill="#272727"/>
            <path id="Path_9855" data-name="Path 9855" d="M490.208,123.177a1.853,1.853,0,0,0-2.622,2.619l14.366,14.371a1.854,1.854,0,0,0,2.622-2.622Z" fill="#272727"/>
            <path id="Path_9856" data-name="Path 9856" d="M468.152,24.031a1.855,1.855,0,0,0,1.854-1.854V1.855a1.854,1.854,0,1,0-3.707,0V22.177a1.854,1.854,0,0,0,1.853,1.854" fill="#272727"/>
            <path id="Path_9857" data-name="Path 9857" d="M446.418,32.4a1.854,1.854,0,1,0,2.624-2.62L434.671,15.413a1.852,1.852,0,1,0-2.621,2.618Z" fill="#272727"/>
            <path id="Path_9858" data-name="Path 9858" d="M488.894,32.168a1.865,1.865,0,0,0,1.314-.544l14.368-14.369a1.853,1.853,0,1,0-2.622-2.62L487.586,29a1.854,1.854,0,0,0,1.308,3.164" fill="#272727"/>
            <path id="Path_9859" data-name="Path 9859" d="M362.675,85.923h31.044V69.655H362.675a7.333,7.333,0,0,0-7.335,7.333v1.6a7.334,7.334,0,0,0,7.335,7.334" fill="#f1f2f2"/>
            <path id="Path_9860" data-name="Path 9860" d="M362.675,85.923h31.044V80.942H366.245a7.334,7.334,0,0,1-7.335-7.334v-1.6a7.442,7.442,0,0,1,.135-1.386,7.325,7.325,0,0,0-3.705,6.367v1.6a7.334,7.334,0,0,0,7.335,7.334" fill="#bcbec0"/>
            <path id="Path_9861" data-name="Path 9861" d="M362.675,67.8H393.72a1.85,1.85,0,0,1,1.851,1.853V85.922a1.852,1.852,0,0,1-1.851,1.854H362.675a9.2,9.2,0,0,1-9.188-9.187v-1.6a9.2,9.2,0,0,1,9.188-9.186m29.191,3.706H362.675a5.488,5.488,0,0,0-5.481,5.48v1.6a5.487,5.487,0,0,0,5.481,5.479h29.191Z" fill="#272727"/>
            <path id="Path_9862" data-name="Path 9862" d="M389.253,98.391h11.02V57.185h-11.02a6.059,6.059,0,0,0-6.06,6.057V92.331a6.059,6.059,0,0,0,6.06,6.06" fill="#f1f2f2"/>
            <path id="Path_9863" data-name="Path 9863" d="M389.253,98.391h5.094a6.06,6.06,0,0,1-6.06-6.06V63.242a6.06,6.06,0,0,1,6.06-6.057h-5.094a6.059,6.059,0,0,0-6.06,6.057V92.331a6.059,6.059,0,0,0,6.06,6.06" fill="#bcbec0"/>
            <path id="Path_9864" data-name="Path 9864" d="M389.253,55.331h11.02a1.854,1.854,0,0,1,1.854,1.854V98.392a1.854,1.854,0,0,1-1.854,1.855h-11.02a7.925,7.925,0,0,1-7.915-7.916V63.243a7.922,7.922,0,0,1,7.915-7.912m9.166,3.708h-9.166a4.211,4.211,0,0,0-4.207,4.2V92.331a4.211,4.211,0,0,0,4.207,4.207h9.166Z" fill="#272727"/>
            <line id="Line_214" data-name="Line 214" y1="24.882" transform="translate(392.384 65.348)" fill="#fff"/>
            <path id="Path_9865" data-name="Path 9865" d="M392.384,63.494a1.855,1.855,0,0,1,1.855,1.853V90.23a1.854,1.854,0,0,1-3.707,0V65.347a1.851,1.851,0,0,1,1.852-1.853" fill="#272727"/>
            <path id="Path_9866" data-name="Path 9866" d="M439.593,44.341H407.932A7.658,7.658,0,0,0,400.273,52v51.574a7.658,7.658,0,0,0,7.659,7.66h31.661Z" fill="#f1f2f2"/>
            <rect id="Rectangle_4315" data-name="Rectangle 4315" width="11.121" height="66.895" transform="translate(429.816 44.341)" fill="#bcbec0"/>
            <path id="Path_9867" data-name="Path 9867" d="M439.593,113.09H407.932a9.522,9.522,0,0,1-9.513-9.513V52a9.523,9.523,0,0,1,9.513-9.516h31.661a1.855,1.855,0,0,1,1.855,1.854v66.9a1.856,1.856,0,0,1-1.855,1.854m-31.661-66.9a5.814,5.814,0,0,0-5.8,5.81v51.574a5.814,5.814,0,0,0,5.8,5.806h29.806V46.193Z" fill="#272727"/>
            <path id="Path_9868" data-name="Path 9868" d="M446.54,107.634a5.592,5.592,0,1,1-11.165,0V47.941a5.593,5.593,0,1,1,11.166,0Z" fill="#f1f2f2"/>
            <path id="Path_9869" data-name="Path 9869" d="M440.959,42.019a5.286,5.286,0,0,0-2.792.8,5.986,5.986,0,0,1,2.792,5.123v59.693a5.986,5.986,0,0,1-2.791,5.125,5.3,5.3,0,0,0,2.792.8,5.759,5.759,0,0,0,5.582-5.923V47.941a5.761,5.761,0,0,0-5.583-5.922" fill="#bcbec0"/>
            <path id="Path_9870" data-name="Path 9870" d="M440.959,115.41a7.62,7.62,0,0,1-7.436-7.776V47.941a7.443,7.443,0,1,1,14.872,0v59.693a7.619,7.619,0,0,1-7.435,7.776m0-71.537a3.916,3.916,0,0,0-3.731,4.068v59.693a3.744,3.744,0,1,0,7.459,0l0-59.693a3.915,3.915,0,0,0-3.73-4.068" fill="#272727"/>
            <path id="Path_9871" data-name="Path 9871" d="M424.7,88.293H411.864a1.854,1.854,0,0,0,0,3.707H424.7a1.854,1.854,0,1,0,0-3.707" fill="#272727"/>
            <path id="Path_9872" data-name="Path 9872" d="M411.864,83.761H424.7a1.853,1.853,0,1,0,0-3.705H411.864a1.853,1.853,0,0,0,0,3.705" fill="#272727"/>
            <path id="Path_9873" data-name="Path 9873" d="M411.864,75.522H424.7a1.853,1.853,0,1,0,0-3.705H411.864a1.853,1.853,0,0,0,0,3.705" fill="#272727"/>
            <path id="Path_9874" data-name="Path 9874" d="M411.864,67.285H424.7a1.854,1.854,0,1,0,0-3.707H411.864a1.854,1.854,0,0,0,0,3.707" fill="#272727"/>
            <path id="Path_9875" data-name="Path 9875" d="M505.086,407.882H438.131a20.634,20.634,0,1,1,0-41.268h66.955a20.634,20.634,0,0,1,0,41.268" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>,
    GoogleWithLogin: <svg xmlns="http://www.w3.org/2000/svg" width="57" height="56" viewBox="0 0 57 56">
      <g id="google" transform="translate(0 -0.023)">
        <ellipse id="Ellipse_1" data-name="Ellipse 1" cx="28.5" cy="28" rx="28.5" ry="28" transform="translate(0 0.023)" fill="#fff"/>
        <path id="_703527_g_google_google_2015_new_google_icon" data-name="703527_g_google_google 2015_new google_icon" d="M74.97,56.369a14.539,14.539,0,0,1,.247,2.68c0,7.29-4.881,12.475-12.251,12.475a12.762,12.762,0,1,1,0-25.524A12.251,12.251,0,0,1,71.5,49.326l-3.6,3.6v-.008a6.989,6.989,0,0,0-4.937-1.93,7.774,7.774,0,0,0,0,15.546c3.821,0,6.421-2.186,6.956-5.185H62.966V56.369Z" transform="translate(-34.044 -31.077)" fill="#333"/>
      </g>
    </svg>,
  
  };
};

export default Svg;
