// user login/registration
export const actionTypes = {
    //event
    SAVE_EVENT_ID: "SAVE_EVENT_ID",

    GET_EVENT_DETAILS_INITIATED: "GET_EVENT_DETAILS_INITIATED",
    GET_EVENT_DETAILS_SUCCESS: "GET_EVENT_DETAILS_SUCCESS",
    GET_EVENT_DETAILS_ERROR: "GET_EVENT_DETAILS_ERROR",


    CHECK_BOOTH_OWNER_INITIATED: "CHECK_BOOTH_OWNER_INITIATED",
    CHECK_BOOTH_OWNER_SUCCESS: "CHECK_BOOTH_OWNER_SUCCESS",
    CHECK_BOOTH_OWNER_ERROR: "CHECK_BOOTH_OWNER_ERROR",

    GET_EXPIRED_EVENT_DETAILS_INITIATED: "GET_EXPIRED_EVENT_DETAILS_INITIATED",
    GET_EXPIRED_EVENT_DETAILS_SUCCESS: "GET_EXPIRED_EVENT_DETAILS_SUCCESS",
    GET_EXPIRED_EVENT_DETAILS_ERROR: "GET_EXPIRED_EVENT_DETAILS_ERROR",

    GET_EVENT_REGISTRATION_FIELDS_INITIATED: "GET_EVENT_REGISTRATION_FIELDS_INITIATED",
    GET_EVENT_REGISTRATION_FIELDS_SUCCESS: "GET_EVENT_REGISTRATION_FIELDS_SUCCESS",
    GET_EVENT_REGISTRATION_FIELDS_ERROR: "GET_EVENT_REGISTRATION_FIELDS_ERROR",

    EVENT_ENTRY_TYPE_FETCHED: "EVENT_ENTRY_TYPE_FETCHED",

    //Embedded
    EMBED_REGISTRATION_INITIATED: "EMBED_REGISTRATION_INITIATED",
    EMBED_REGISTRATION_SUCCESS: "EMBED_REGISTRATION_SUCCESS",
    EMBED_REGISTRATION_ERROR: "EMBED_REGISTRATION_ERROR",

    

    EMBED_OTP_SUBMIT_INITIATED: "EMBED_OTP_SUBMIT_INITIATED",
    EMBED_OTP_SUBMIT_SUCCESS: "EMBED_OTP_SUBMIT_SUCCESS",
    EMBED_OTP_SUBMIT_ERROR: "OTP_SUBMIT_ERROR",

    EMBED_TOKEN_VALIDATION_INITIATED: " EMBED_TOKEN_VALIDATION_INITIATED",
    EMBED_TOKEN_VALIDATION_SUCCESS: " EMBED_TOKEN_VALIDATION_SUCCESS",
    EMBED_TOKEN_VALIDATION_ERROR: " EMBED_TOKEN_VALIDATION_ERROR",

    EMBED_EVENT_REGISTRATION_FIELDS_INITIATED: "EMBED_EVENT_REGISTRATION_FIELDS_INITIATED",
    EMBED_EVENT_REGISTRATION_FIELDS_SUCCESS: "EMBED_EVENT_REGISTRATION_FIELDS_SUCCESS",
    EMBED_EVENT_REGISTRATION_FIELDS_ERROR: "EMBED_EVENT_REGISTRATION_FIELDS_ERROR",

    EMBED_EVENT_FORM_SUBMIT_INITIATED: "EMBED_EVENT_FORM_SUBMIT_INITIATED",
    EMBED_EVENT_FORM_SUBMIT_SUCCESS: "EMBED_EVENT_FORM_SUBMIT_SUCCESS",
    EMBED_EVENT_FORM_SUBMIT_ERROR: "EMBED_EVENT_FORM_SUBMIT_ERROR",

    RESET_EMBED_REGISTRATION_STATUS: "RESET_EMBED_REGISTRATION_STATUS",
    COMPLETE_EMBED_REGISTRATION: 'COMPLETE_EMBED_REGISTRATION',

    ON_EMBED_REGISTRATION_SUCCESS: 'ON_EMBED_REGISTRATION_SUCCESS',

    // auth 
    REGISTRATION_INITIATED: "REGISTRATION_INITIATED",
    REGISTRATION_SUCCESS: "REGISTRATION_SUCCESS",
    REGISTRATION_ERROR: "REGISTRATION_ERROR",

    DIRECT_LOGIN_INITIATED: " DIRECT_LOGIN_INITIATED",
    DIRECT_LOGIN_SUCCESS: " DIRECT_LOGIN_SUCCESS",
    DIRECT_LOGIN_ERROR: " DIRECT_LOGIN_ERROR",

    OTP_SUBMIT_INITIATED: "OTP_SUBMIT_INITIATED",
    OTP_SUBMIT_SUCCESS: "OTP_SUBMIT_SUCCESS",
    OTP_SUBMIT_ERROR: "OTP_SUBMIT_ERROR",

    TOKEN_VALIDATION_INITIATED: "TOKEN_VALIDATION_INITIATED",
    TOKEN_VALIDATION_SUCCESS: "TOKEN_VALIDATION_SUCCESS",
    TOKEN_VALIDATION_ERROR: "TOKEN_VALIDATION_ERROR",

    EVENT_REGISTRATION_FIELD_CHANGED: "EVENT_REGISTRATION_FIELD_CHANGED",
    EVENT_REGISTRATION_DISCLAIMER_CHANGED: "EVENT_REGISTRATION_DISCLAIMER_CHANGED",

    EVENT_FORM_SUBMIT_INITIATED: "EVENT_FORM_SUBMIT_INITIATED",
    EVENT_FORM_SUBMIT_SUCCESS: "EVENT_FORM_SUBMIT_SUCCESS",
    EVENT_FORM_SUBMIT_ERROR: "EVENT_FORM_SUBMIT_ERROR",

    VERIFY_SPEAKER_TOKEN_INITIATED: "VERIFY_SPEAKER_TOKEN_INITIATED",
    VERIFY_SPEAKER_TOKEN_SUCCESS: "VERIFY_SPEAKER_TOKEN_SUCCESS",
    VERIFY_SPEAKER_TOKEN_ERROR: "VERIFY_SPEAKER_TOKEN_ERROR",

    ON_VERIFY_SPEAKER_TOKEN_SUCCESS: 'ON_VERIFY_SPEAKER_TOKEN_SUCCESS',

    SOCIAL_REGISTRATION_INITIATED: "SOCIAL_REGISTRATION_INITIATED",
    SOCIAL_REGISTRATION_SUCCESS: "SOCIAL_REGISTRATION_SUCCESS",
    SOCIAL_REGISTRATION_ERROR: "SOCIAL_REGISTRATION_ERROR",


    GET_LOBBY_DETAILS_INITIATED: "GET_LOBBY_DETAILS_INITIATED",
    GET_LOBBY_DETAILS_SUCCESS: "GET_LOBBY_DETAILS_SUCCESS",
    GET_LOBBY_DETAILS_ERROR: "GET_LOBBY_DETAILS_ERROR",

    GET_STAGE_DETAILS_INITIATED: "GET_STAGE_DETAILS_INITIATED",
    GET_STAGE_DETAILS_SUCCESS: "GET_STAGE_DETAILS_SUCCESS",
    GET_STAGE_DETAILS_ERROR: "GET_STAGE_DETAILS_ERROR",


    GET_ENTER_STAGE_DETAILS_INITIATED: "GET_ENTER_STAGE_DETAILS_INITIATED",
    GET_ENTER_STAGE_DETAILS_SUCCESS: "GET_ENTER_STAGE_DETAILS_SUCCESS",
    GET_ENTER_STAGE_DETAILS_ERROR: "GET_ENTER_STAGE_DETAILS_ERROR",
    GET_STREAMING_DATA: "GET_STREAMING_DATA",

    SET_ENTER_STAGE_INTRO_VIDEO_STATUS: 'SET_ENTER_STAGE_INTRO_VIDEO_STATUS',

    SET_LOBBY_USER_PROFILE_SECTION_STATUS: 'SET_LOBBY_USER_PROFILE_SECTION_STATUS',

    LOGOUT: 'LOGOUT',

    COMPLETE_REGISTRATION: 'COMPLETE_REGISTRATION',
    RESET_EVENT_REGISTRATION_STATUS: 'RESET_EVENT_REGISTRATION_STATUS',
    SET_LOBBY_INTRO_VIDEO_STATUS: 'SET_LOBBY_INTRO_VIDEO_STATUS',

    GET_MESSAGES_LIST: 'GET_MESSAGES_LIST',
    GET_MESSAGES_LIST_ITEM: 'GET_MESSAGES_LIST_ITEM',
    GET_STAGE_USER_LIST: 'GET_STAGE_USER_LIST',
    GET_ALL_STAGE_USER_LIST: 'GET_ALL_STAGE_USER_LIST',
    GET_ALL_PRIVATE_CHAT_NOTIFICATION_LIST: 'GET_ALL_PRIVATE_CHAT_NOTIFICATION_LIST',
    TOGGLE_PEOPLE_CHAT: "TOGGLE_PEOPLE_CHAT",
    CHAT_LIST: "CHAT_LIST",
    EMOJI: "EMOJI",
    FROM_WHICH_CHAT: "FROM_WHICH_CHAT",
    HIDE_STAGE_CHAT: "HIDE_STAGE_CHAT",
    HIDE_EXPO_CHAT: "HIDE_EXPO_CHAT",
    HIDE_BACKSTAGE_CHAT:"HIDE_BACKSTAGE_CHAT",
    TOGGLE_PUBLIC_CHAT:"TOGGLE_PUBLIC_CHAT",
    DELETE_PUBLIC_CHAT:"DELETE_PUBLIC_CHAT",

    GET_PEOPLE_LIST: 'GET_PEOPLE_LIST',
    GET_SPEAKERS_LIST: 'GET_SPEAKERS_LIST',
    GET_STAGE_RECORDING_STATUS: 'GET_STAGE_RECORDING_STATUS',
    GET_BACK_STAGE_PERMISSION:'GET_BACK_STAGE_PERMISSION',

    SET_LOBBY_CHAT_SECTION_ACTIVE: 'SET_LOBBY_CHAT_SECTION_ACTIVE',

    SET_LOBBY_PERSONAL_CHAT_DETAILS: 'SET_LOBBY_PERSONAL_CHAT_DETAILS',
    HIDE_CHAT_NOTIFICATION: "HIDE_CHAT_NOTIFICATION",

    GET_AGORA_CLIENT: 'GET_AGORA_CLIENT',
    REINIT_AGORA_CLIENT: 'REINIT_AGORA_CLIENT',

    GET_POLLS_LIST: 'GET_POLLS_LIST',
    GET_POLLS_LIST_ITEM: 'GET_POLLS_LIST_ITEM',

    GET_QUESTIONS_LIST: 'GET_QUESTIONS_LIST',
    SAVE_USER_RESPONDED_QNA: 'SAVE_USER_RESPONDED_QNA',
    HIDE_QNA_NOTIFICATION: 'HIDE_QNA_NOTIFICATION',

    ON_EVENT_REGISTRAION_SUCCESS: 'ON_EVENT_REGISTRAION_SUCCESS',
    SAVE_USER_RESPONDED_POLL: 'SAVE_USER_RESPONDED_POLL',
    HIDE_POLL_NOTIFICATION: 'HIDE_POLL_NOTIFICATION',
    ADD_MODULE_ID: 'ADD_MODULE_ID',

    SAVE_NEW_BACK_STAGE_SPEAKER: 'SAVE_NEW_BACK_STAGE_SPEAKER',
    REMOVE_BACK_STAGE_SPEAKER: 'REMOVE_BACK_STAGE_SPEAKER',
    REMOVE_FRONT_STAGE_SPEAKER: 'REMOVE_FRONT_STAGE_SPEAKER',

    SAVE_NEW_FRONT_STAGE_SPEAKER: 'SAVE_NEW_FRONT_STAGE_SPEAKER',

    SAVE_NEW_FRONT_STAGE_AUDIENCE: 'SAVE_NEW_FRONT_STAGE_AUDIENCE',
    SAVE_NEW_AUDIENCE_BACK_STAGE_REQUEST: 'SAVE_NEW_AUDIENCE_BACK_STAGE_REQUEST',
    SAVE_NEW_AUDIENCE_JOIN_REQUEST_STATUS: 'SAVE_NEW_AUDIENCE_JOIN_REQUEST_STATUS',

    //AGORA EVENTS
    AGORA_CHANGE_ROOM: 'AGORA_CHANGE_ROOM',
    SET_IS_AGORA_READY: 'SET_IS_AGORA_READY',
    
    SET_CAMS: 'SET_CAMS',
    SET_MICS: 'SET_MICS',
    SET_CURRENT_MIC: 'SET_CURRENT_MIC',
    SET_CURRENT_CAM: 'SET_CURRENT_CAM',

    SET_LOCAL_TRACKS: 'SET_LOCAL_TRACKS',
    SET_PREVIEW_TRACKS: 'SET_PREVIEW_TRACKS',
    SET_LIVE_TRACKS: 'SET_LIVE_TRACKS',
    SET_LOCAL_CLIENT: 'SET_LOCAL_CLIENT',
    SET_VIRTUAL_BACKGROUND: 'SET_VIRTUAL_BACKGROUND',
    SET_PREVIEW_CLIENT: 'SET_PREVIEW_CLIENT',
    SET_LIVE_CLIENT: 'SET_LIVE_CLIENT',
    SET_SCREEN_SHARE_PREVIEW_TRACKS: 'SET_SCREEN_SHARE_PREVIEW_TRACKS',
    SET_SCREEN_SHARE_LIVE_TRACKS: 'SET_SCREEN_SHARE_LIVE_TRACKS',

    SET_AGORA_FUNCTIONS: 'SET_AGORA_FUNCTIONS',
    // SET_AGORA_REMOTE_VIDEO_CONTAINER: 'SET_AGORA_REMOTE_VIDEO_CONTAINER',
    // SET_AGORA_LOCAL_VIDEO_CONTAINER: 'SET_AGORA_LOCAL_VIDEO_CONTAINER',

    SET_SHOULD_AGORA_INIT_RENDER: 'SET_SHOULD_AGORA_INIT_RENDER',

    TOGGLE_MIC_ENABLED: 'TOGGLE_MIC_ENABLED',
    TOGGLE_CAMERA_ENABLED: 'TOGGLE_CAMERA_ENABLED',

    GET_ROOM_DETAILS_INITIATED: "GET_ROOM_DETAILS_INITIATED",
    GET_ROOM_DETAILS_SUCCESS: "GET_ROOM_DETAILS_SUCCESS",
    GET_ROOM_DETAILS_ERROR: "GET_ROOM_DETAILS_ERROR",

    GET_ROOM_LINK_INITIATED: "GET_ROOM_LINK_INITIATED",
    GET_ROOM_LINK_SUCCESS: "GET_ROOM_LINK_SUCCESS",
    GET_ROOM_LINK_ERROR: "GET_ROOM_LINK_ERROR",

    GET_ROOM_CHAIR_DETAILS: "GET_ROOM_CHAIR_DETAILS",
    GET_ROOM_CHAIR_DELETE_DETAILS: "GET_ROOM_CHAIR_DELETE_DETAILS",
    SAVE_ROOM_CHAIR_DETAIL: "SAVE_ROOM_CHAIR_DETAIL",
    REMOVE_ROOM_CHAIR_DETAIL: "REMOVE_ROOM_CHAIR_DETAIL",
    ENABLE_ADD_NOTIFICATION:"ENABLE_ADD_NOTIFICATION",
    NOTIFICATION_DATA:"NOTIFICATION_DATA",

    GET_EXPO_ALL_LIST_INITIATED: "GET_EXPO_ALL_LIST_INITIATED",
    GET_EXPO_ALL_LIST_SUCCESS: "GET_EXPO_ALL_LIST_SUCCESS",
    GET_EXPO_ALL_LIST_ERROR: "GET_EXPO_ALL_LIST_ERROR",

    GET_ZONE_EXPO_LIST_INITIATED: "GET_ZONE_EXPO_LIST_INITIATED",
    GET_ZONE_EXPO_LIST_SUCCESS: "GET_ZONE_EXPO_LIST_SUCCESS",
    GET_ZONE_EXPO_LIST_ERROR: "GET_ZONE_EXPO_LIST_ERROR",

    EXPO_DETAIL_INITIATED: "EXPO_DETAIL_INITIATED",
    EXPO_DETAIL_SUCCESS: "EXPO_DETAIL_SUCCESS",
    EXPO_DETAIL_ERROR: "EXPO_DETAIL_ERROR",

    GET_EXPO_DETAIL_INITIATED: "GET_EXPO_DETAIL_INITIATED",
    GET_EXPO_DETAIL_SUCCESS: "GET_EXPO_DETAIL_SUCCESS",
    GET_EXPO_DETAIL_ERROR: "GET_EXPO_DETAIL_ERROR",
    GET_EXPO_USER_LIST: 'GET_EXPO_USER_LIST',
    GET_ALL_EXPO_USER_LIST: 'GET_ALL_EXPO_USER_LIST',

    GET_EXPO_ALL_LIST_THEME_INITIATED: "GET_EXPO_ALL_LIST_THEME_INITIATED",
    GET_EXPO_ALL_LIST_THEME_SUCCESS: "GET_EXPO_ALL_LIST_THEME_SUCCESS",
    GET_EXPO_ALL_LIST_THEME_ERROR: "GET_EXPO_ALL_LIST_THEME_ERROR",

    JOIN_BACK_STAGE: "JOIN_BACK_STAGE",
    LEAVE_BACK_STAGE: "LEAVE_BACK_STAGE",

    JOIN_FRONT_STAGE: "JOIN_FRONT_STAGE",
    LEAVE_FRONT_STAGE: "LEAVE_FRONT_STAGE",

    USER_PROFILE_INITIATED: "USER_PROFILE_INITIATED",
    USER_PROFILE_SUCCESS: "USER_PROFILE_SUCCESS",
    USER_PROFILE_ERROR: "USER_PROFILE_ERROR",
    GET_USER_DETAILS: "GET_USER_DETAILS",
    GET_USER_INTERESTS: "GET_USER_INTERESTS",
    GET_USER_TIMEZONE_LIST:"GET_USER_TIMEZONE_LIST",
    GET_LANG_LIST:'GET_LANG_LIST',
    SET_LANG_REGION:'SET_LANG_REGION',
    SET_COUNTRY_CODE_LIST:'SET_COUNTRY_CODE_LIST',

    ADD_EVENT_LISTENER: "ADD_EVENT_LISTENER",
    REMOVE_ALL_EVENT_LISTENER: "REMOVE_ALL_EVENT_LISTENER",
    ADD_CURRENT_LOCATION: "ADD_CURRENT_LOCATION",

    REQUEST_TO_BOOTH_OWNERS: "REQUEST_TO_BOOTH_OWNERS",
    REQUEST_TO_VISITORS: "REQUEST_TO_VISITORS",

    GET_SCHEDULE_DETAILS_INITIATED: "GET_SCHEDULE_DETAILS_INITIATED",
    GET_SCHEDULE_DETAILS_SUCCESS: "GET_SCHEDULE_DETAILS_SUCCESS",
    GET_SCHEDULE_DETAILS_ERROR: "GET_SCHEDULE_DETAILS_ERROR",

    GET_SCHEDULE_LIST_THEME_INITIATED: "GET_SCHEDULE_LIST_THEME_INITIATED",
    GET_SCHEDULE_LIST_THEME_SUCCESS: "GET_SCHEDULE_LIST_THEME_SUCCESS",
    GET_SCHEDULE_LIST_THEME_ERROR: "GET_SCHEDULE_LIST_THEME_ERROR",
    GET_LIVE_SCHEDULE: "GET_LIVE_SCHEDULE",


    GET_STAGE_MEDIA_BROADCAST: "GET_STAGE_MEDIA_BROADCAST",
    GET_PDF_SHARE: "GET_PDF_SHARE",
    GET_NOTIFICATION_LIST_ITEM: "GET_NOTIFICATION_LIST_ITEM",
    SAVE_STAGE_MEDIA_LIST: "SAVE_STAGE_MEDIA_LIST",
    REMOVE_STAGE_MEDIA: "REMOVE_STAGE_MEDIA",
    SAVE_STAGE_BROADCASTING_STATUS: "SAVE_STAGE_BROADCASTING_STATUS",
    SAVE_STREAM_USERS: "SAVE_STREAM_USERS",

    GET_STATE_INITIATED: "GET_STATE_INITIATED",
    GET_STATE_SUCCESS: "GET_STATE_SUCCESS",
    GET_STATE_ERROR: "GET_STATE_ERROR",

    SET_ZONES_WITH_STATUS: "SET_EVENT_ZONES_WITH_STATUS",

    SAVE_ROOM_CHAIR_JOIN_INITIATED: "SAVE_ROOM_CHAIR_TIME_SPENT_INITIATED",
    SAVE_ROOM_CHAIR_JOIN_SUCCESS: " SAVE_ROOM_CHAIR_TIME_SPENT_SUCCESS",
    SAVE_ROOM_CHAIR_JOIN_ERROR: "SAVE_ROOM_CHAIR_TIME_SPENT_ERROR",

    SAVE_ROOM_CHAIR_LEAVE_INITIATED: "SAVE_ROOM_CHAIR_LEAVE_INITIATED",
    SAVE_ROOM_CHAIR_LEAVE_SUCCESS: "SAVE_ROOM_CHAIR_LEAVE_SUCCESS",
    SAVE_ROOM_CHAIR_LEAVE_ERROR: "SAVE_ROOM_CHAIR_LEAVE_ERROR",


    GET_NOTIFICATION:"GET_NOTIFICATION",
    ON_SEEN_NOTIFICATION:"ON_SEEN_NOTIFICATION",

    GET_SESSION_SPEAKER_DETAIL: "GET_SESSION_SPEAKER_DETAIL",
    GET_UPDATED_SESSION_SPEAKERS: "GET_UPDATED_SESSION_SPEAKERS",

    SESSION_SPEAKER_INITIATED: "SESSION_SPEAKER_INITIATED",
    SESSION_SPEAKER_SUCCESS: "SESSION_SPEAKER_SUCCESS",
    SESSION_SPEAKER_ERROR: "SESSION_SPEAKER_ERROR",

    DELETE_SESSION_SPEAKER: "DELETE_SESSION_SPEAKER",
    
    UPDATE_STAGE_BACKGROUND_IMAGE: "UPDATE_STAGE_BACKGROUND_IMAGE",
    ON_ACTIVE_SPEAKER:"ON_ACTIVE_SPEAKER",

    BOOTH_FILE_ACCESS_INITIATED: "BOOTH_FILE_ACCESS_INITIATED",
    BOOTH_FILE_ACCESS_SUCCESS: "BOOTH_FILE_ACCESS_SUCCESS",
    BOOTH_FILE_ACCESS_ERROR: "BOOTH_FILE_ACCESS_ERROR"
}