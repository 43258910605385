import axios from 'axios';
import * as constants from '../../constants';
import { showToast } from "../../utils";
import { actionTypes } from '../actionTypes';
import { getAllDataByPath, getStageUserListWithListener } from "../../utils/firebase";

const { getUrl } = constants;

export const getExpoAllList = (eventId, callback) => async (dispatch) => {
    const { GET_EXPO_ALL_LIST } = constants;
    dispatch({
        type: actionTypes.GET_EXPO_ALL_LIST_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_EXPO_ALL_LIST, eventId));
        if (res.data.data && res.data.data.booth && res.data.data.booth.LARGE) {
            dispatch({
                type: actionTypes.GET_EXPO_ALL_LIST_SUCCESS,
                payload: res.data.data.booth,
                code: res.data.code
            });
        }
        callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EXPO_ALL_LIST_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getExpoBoothThemeList = (eventId) => async (dispatch) => {
    const { GET_BOOTH_LIST_FOR_THEME } = constants;
    dispatch({
        type: actionTypes.GET_EXPO_ALL_LIST_THEME_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_BOOTH_LIST_FOR_THEME, eventId));
        if (res.data.data) {
            dispatch({
                type: actionTypes.GET_EXPO_ALL_LIST_THEME_SUCCESS,
                payload: res.data.data,
            });
        }
        // callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EXPO_ALL_LIST_THEME_ERROR
        });
        // showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getExpoListByZoneId = (eventId, zoneId, callback) => async (dispatch) => {
    const { GET_EXPO_LIST_BY_ZONE } = constants;
    dispatch({
        type: actionTypes.GET_ZONE_EXPO_LIST_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_EXPO_LIST_BY_ZONE, eventId, zoneId));
        if (res.data.data && res.data.data.booth && res.data.data.booth.LARGE) {
            dispatch({
                type: actionTypes.GET_ZONE_EXPO_LIST_SUCCESS,
                payload: res.data.data.booth,
                code: res.data.code
            });
        }
        callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ZONE_EXPO_LIST_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const getExpoDetail = (eventId, expoId, callback) => async (dispatch) => {
    const { GET_EXPO_DETAIL } = constants;
    dispatch({
        type: actionTypes.GET_EXPO_DETAIL_INITIATED
    });

    try {
        const res = await axios.get(getUrl(GET_EXPO_DETAIL, eventId, expoId));
        dispatch({
            type: actionTypes.GET_EXPO_DETAIL_SUCCESS,
            payload: res.data.data,
            code: res.data.code
        });
        callback(res.data.data);
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EXPO_DETAIL_ERROR
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

export const onExpoDetailSave = (eventId, expoId, values) => async(dispatch) => {
    const { UPDATE_EXPO_DETAIL } = constants;
    dispatch({
        type: actionTypes.EXPO_DETAIL_INITIATED
    });
    try {
        const res = await axios.post(getUrl(UPDATE_EXPO_DETAIL, eventId, expoId), values);
        dispatch({
            type: actionTypes.EXPO_DETAIL_SUCCESS,
            payload: res.data
        });
        showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.EXPO_DETAIL_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data?.message || 'some error occurred' });
    }
}

// get expo user list
export const getExpoUserList = (roomName, cb) => async (dispatch) => {
    if (roomName) {
        const path = roomName;
        let userList = [];
        getStageUserListWithListener(path, (data) => {
            if (data && data.uid) {
                if (data.added) {
                    userList = [...userList, data];
                } else if (data.removed) {
                    userList = userList.filter((user) => user.uid !== data.uid);
                }
            }
            dispatch({
                type: actionTypes.GET_EXPO_USER_LIST,
                payload: { userList, roomName }
            });
            if (cb) {
                cb(data)
            }
        })
    }
}

// get all expo user list
export const getAllExpoUserList = (roomName, cb) => async (dispatch) => {
    if (roomName) {
        const path = roomName;
        let userList = [];
        getAllDataByPath(path, (data) => {
            if (data && data.id) {
                for (const property in data) {
                    if (property && property !== "id") {
                        let roomData = data[property];
                        for (const val in roomData) {
                            if (val) {
                                userList.push(roomData[val]);
                            }
                        }
                    }
                }
            }
            dispatch({
                type: actionTypes.GET_ALL_EXPO_USER_LIST,
                payload: { userList, roomName }
            });
            if (cb) {
                cb(data)
            }
        })
    }
}

/**
 * boothFileAccess
 * @param {*} values | values
 * @returns | file
 */
export const boothFileAccess = (eventId, expoId, values) => async (dispatch) => {
    const {BOOTH_FILE_ACCESS_URL} = constants;
    
    dispatch({
        type: actionTypes.BOOTH_FILE_ACCESS_INITIATED
    });

    try {
        const res = await axios.post(getUrl(BOOTH_FILE_ACCESS_URL, eventId, expoId), values);
        dispatch({
            type: actionTypes.BOOTH_FILE_ACCESS_SUCCESS,
            payload: res.data
        });

        // showToast({ variant: 'success', message: res.data.message });
    } catch (error) {
        dispatch({
            type: actionTypes.BOOTH_FILE_ACCESS_ERROR,
        });
        showToast({ variant: 'error', message: error?.response?.data || 'some error occurred' });
    }

}

