import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionTypes } from '../actionTypes';

const initialState = {
    authToken: null,
    eventToken: null,
    userData: null,
    loading: false,
    authBootstrapingDone: false,
    userProfileUpdateStatus: null,
    stateData: null,
    userProfileData: {},
    networkInterest: [],
    timeZone: [],
    langList: [],
    langRegion: "English",
    isEmbeddedRegistration: 0,
    checkBoothOwnerData: {},
    boothOwnerData: {},
    directLoginData: {},
    countryCode: []
}

export default persistReducer({ storage, key: "webinar-events-auth", whitelist: ["authToken", "tokenType", "userData", "countryCode"] },
    (state = initialState, action) => {
        const { type, payload, code, message } = action;
        switch (type) {
            case 'persist/REHYDRATE': {
                return { ...state, ...payload, authBootstrapingDone: true, tokenValidated: false, userAlreadyLoggedIn: payload?.authToken ? true : true }
            }
            case actionTypes.REGISTRATION_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.REGISTRATION_SUCCESS: {
                return { ...state, loading: false, require_otp: code === 'send_otp', isEmbeddedRegistration: 0, message: message };
            }
            case actionTypes.REGISTRATION_ERROR: {
                return { ...state, loading: false, require_otp: false };
            }
            case actionTypes.OTP_SUBMIT_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.OTP_SUBMIT_SUCCESS: {
                return { ...state, loading: false, loginSuccess: true, authToken: payload.token.token, tokenType: payload.token.token_type, userAlreadyLoggedIn: true, boothOwnerData: payload.booth_owner_data };
            }
            case actionTypes.OTP_SUBMIT_ERROR: {
                return { ...state, loading: false, loginSuccess: false, authToken: null, tokenType: null };
            }

            case actionTypes.EMBED_REGISTRATION_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.EMBED_REGISTRATION_SUCCESS: {
                return { ...state, loading: false, require_otp: code === 'send_otp', isEmbeddedRegistration: 1, message: message };
            }
            case actionTypes.EMBED_REGISTRATION_ERROR: {
                return { ...state, loading: false, require_otp: false };
            }
            case actionTypes.EMBED_OTP_SUBMIT_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.EMBED_OTP_SUBMIT_SUCCESS: {
                return { ...state, loading: false, loginSuccess: true, authToken: payload.token.token, tokenType: payload.token.token_type, userAlreadyLoggedIn: true };
            }
            case actionTypes.EMBED_OTP_SUBMIT_ERROR: {
                return { ...state, loading: false, loginSuccess: false, authToken: null, tokenType: null };
            }

            case actionTypes.VERIFY_SPEAKER_TOKEN_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.VERIFY_SPEAKER_TOKEN_SUCCESS: {
                return { ...state, loading: false, loginSuccess: true, authToken: payload.token.token, tokenType: payload.token.token_type, userAlreadyLoggedIn: true };
            }
            case actionTypes.VERIFY_SPEAKER_TOKEN_ERROR: {
                return { ...state, loading: false, loginSuccess: false, authToken: null, tokenType: null };
            }

            case actionTypes.EMBED_TOKEN_VALIDATION_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.EMBED_TOKEN_VALIDATION_SUCCESS: {
                return { ...state, loading: false, loginSuccess: true, tokenValidated: true, userData: payload };
            }
            case actionTypes.EMBED_TOKEN_VALIDATION_ERROR: {
                return { ...state, loading: false, loginSuccess: false, userTokenInvalid: true };
            }

            case actionTypes.TOKEN_VALIDATION_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.TOKEN_VALIDATION_SUCCESS: {
                return { ...state, loading: false, loginSuccess: true, tokenValidated: true, userData: payload };
            }
            case actionTypes.TOKEN_VALIDATION_ERROR: {
                return { ...state, loading: false, loginSuccess: false, userTokenInvalid: true };
            }
            case actionTypes.SOCIAL_REGISTRATION_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.SOCIAL_REGISTRATION_SUCCESS: {
                return { ...state, loading: false, loginSuccess: true, authToken: payload.token.token, tokenType: payload.token.token_type, userAlreadyLoggedIn: true };
            }
            case actionTypes.SOCIAL_REGISTRATION_ERROR: {
                return { ...state, loading: false, loginSuccess: false, authToken: null, tokenType: null };
            }
            case actionTypes.LOGOUT: {
                storage.removeItem('persist:webinar-events-lobby')
                storage.removeItem('persist:webinar-events-auth')
                storage.removeItem('persist:webinar-events-expo')
                storage.removeItem('persist:webinar-events-listeners')
                storage.removeItem('persist:webinar-events-enter-stage')
                storage.removeItem('persist:webinar-events-schedule')
                storage.removeItem('persist:webinar-events-rooms');
                storage.removeItem('persist:webinar-events');

                return { ...initialState, authBootstrapingDone: true };
            }
            case actionTypes.ON_EVENT_REGISTRAION_SUCCESS: {
                return { ...state, userData: payload }
            }

            case actionTypes.ON_EMBED_REGISTRATION_SUCCESS: {
                return { ...state, userData: payload }
            }
            // case actionTypes.SET_LOBBY_USER_PROFILE_SECTION_STATUS:{
            //     return {...state, user_profile_complete:true}
            // }
            case actionTypes.USER_PROFILE_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.USER_PROFILE_SUCCESS: {
                return { ...state, loading: false, userProfileUpdateStatus: payload };
            }
            case actionTypes.USER_PROFILE_ERROR: {
                return { ...state, loading: false, userProfileUpdateStatus: null };
            }
            case actionTypes.DIRECT_LOGIN_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.DIRECT_LOGIN_SUCCESS: {
                return { ...state, loading: false, loginSuccess: true, authToken: payload.token, tokenType: payload.token.token_type, userAlreadyLoggedIn: true, directLoginData: payload };
            }
            case actionTypes.DIRECT_LOGIN_ERROR: {
                return { ...state, loading: false, directLoginData: null };
            }
            case actionTypes.GET_USER_DETAILS: {
                return { ...state, userProfileData: payload }
            }
            case actionTypes.CHECK_BOOTH_OWNER_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.CHECK_BOOTH_OWNER_SUCCESS: {
                return { ...state, loading: false, checkBoothOwnerData: payload };
            }
            case actionTypes.CHECK_BOOTH_OWNER_ERROR: {
                return { ...state, loading: false, checkBoothOwnerData: null };
            }
            case actionTypes.GET_USER_INTERESTS: {
                return { ...state, networkInterest: payload }
            }
            case actionTypes.GET_USER_TIMEZONE_LIST: {
                return { ...state, timeZone: payload }
            }
            case actionTypes.GET_LANG_LIST: {
                return { ...state, langList: payload }
            }
            case actionTypes.SET_LANG_REGION: {
                return { ...state, langRegion: payload }
            }
            case actionTypes.SET_COUNTRY_CODE_LIST: {
                return { ...state, countryCode: payload }
            }
            default:
                return state;
        }
    }
);
