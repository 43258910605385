import { actionTypes } from '../actionTypes';
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
    eventData: null,
    expiredEventData: null,
    eventRegistrationFieldsFetched: false,
    eventRegistrationSuccessFull: false,
    eventCanCheckIn: false,
    speakers: [],
    embeddedRegistrationSuccess: false,
    notifications: {}
}

export default persistReducer({
    storage, key: "webinar-events",
    whitelist: ["eventRegistrationSuccessFull", "eventCanCheckIn"] 
},
    (state = initialState, action) => {
        const { type, payload, code } = action;
        switch (type) {
            case actionTypes.SAVE_EVENT_ID: {
                return { ...state, eventId: payload }
            }
            case actionTypes.GET_EVENT_DETAILS_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.GET_EVENT_DETAILS_SUCCESS: {
                return { ...state, loading: false, eventData: payload, eventCanCheckIn: payload.can_check_in };
            }
            case actionTypes.GET_EVENT_DETAILS_ERROR: {
                return { ...state, loading: false, eventData: null };
            }
            case actionTypes.GET_EXPIRED_EVENT_DETAILS_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.GET_EXPIRED_EVENT_DETAILS_SUCCESS: {
                return { ...state, loading: false, expiredEventData: payload };
            }
            case actionTypes.GET_EXPIRED_EVENT_DETAILS_ERROR: {
                return { ...state, loading: false }
            }
            case actionTypes.GET_EVENT_REGISTRATION_FIELDS_SUCCESS: {
                return { ...state, eventRegistrationFieldsFetched: true, registrationFields: payload, eventRegistrationSuccessFull: payload.registration_complete === 1 ? true : false }
            }
            case actionTypes.GET_EVENT_REGISTRATION_FIELDS_ERROR: {
                return { ...state, eventRegistrationFieldsFetched: false, registrationFields: null }
            }

            case actionTypes.EMBED_EVENT_REGISTRATION_FIELDS_SUCCESS: {
                return { ...state, eventRegistrationFieldsFetched: true, registrationFields: payload, eventRegistrationSuccessFull: payload.registration_complete === 1 ? true : false }
            }
            case actionTypes.EMBED_EVENT_REGISTRATION_FIELDS_ERROR: {
                return { ...state, eventRegistrationFieldsFetched: false, registrationFields: null }
            }

            case actionTypes.EVENT_ENTRY_TYPE_FETCHED: {
                return { ...state, eventEntryType: payload }
            }
            case actionTypes.EVENT_REGISTRATION_FIELD_CHANGED: {
                const newArr = [...state.registrationFields.custom_form];
                const findIndex = newArr.findIndex(e => e.field_name === payload.name);

                if (findIndex > -1) {
                    newArr[findIndex] = { ...newArr[findIndex], value: payload.value, pattern: payload?.pattern };
                }

                return { ...state, registrationFields: { ...state.registrationFields, custom_form: newArr } }
            }
            case actionTypes.EVENT_REGISTRATION_DISCLAIMER_CHANGED: {
                return { ...state, registrationFields: { ...state.registrationFields, disclaimer: [...state.registrationFields.disclaimer].map(d => d.uid === payload.uid ? { ...d, checked: payload.checked } : d) } }
            }

            case actionTypes.EMBED_EVENT_FORM_SUBMIT_INITIATED: {
                return { ...state, loading: true }
            }
            case actionTypes.EMBED_EVENT_FORM_SUBMIT_SUCCESS:
            case actionTypes.COMPLETE_EMBED_REGISTRATION:
                {
                    return { ...state, loading: false, embeddedRegistrationSuccess: true, eventRegistrationSuccessFull: true }
                }
            case actionTypes.EMBED_EVENT_FORM_SUBMIT_ERROR: {
                return { ...state, loading: false, eventRegistrationFailed: true }
            }
            case actionTypes.RESET_EMBED_REGISTRATION_STATUS: {
                return { ...state, loading: false, eventRegistrationSuccessFull: false }
            }

            case actionTypes.EVENT_FORM_SUBMIT_INITIATED: {
                return { ...state, loading: true }
            }
            case actionTypes.EVENT_FORM_SUBMIT_SUCCESS:
            case actionTypes.COMPLETE_REGISTRATION:
                {
                    return { ...state, loading: false, eventRegistrationSuccessFull: true }
                }
            case actionTypes.EVENT_FORM_SUBMIT_ERROR: {
                return { ...state, loading: false, eventRegistrationFailed: true }
            }
            case actionTypes.RESET_EVENT_REGISTRATION_STATUS: {
                return { ...state, loading: false, eventRegistrationSuccessFull: false }
            }
            case actionTypes.GET_PEOPLE_LIST: {
                return { ...state, people: payload }
            }
            case actionTypes.GET_SPEAKERS_LIST: {
                return { ...state, speakers: payload }
            }
            case actionTypes.GET_NOTIFICATION_LIST_ITEM: {
                // return {...state, notifications: state.notifications[payload.category] ? {...state.notifications, [payload.category]: state.notifications[payload.category].findIndex(item => item.id === payload.id) == -1 ? [...state.notifications[payload.category], payload] : state.notifications[payload.category]} : {...state.notifications, [payload.category]: [payload] }}
                const filteredNotifications = state.notifications[payload.category] ? [...state.notifications[payload.category].filter(item => item.id !== payload.id)] : [];
                // return {...state, notifications: state.notifications[payload.category] ? {...state.notifications, [payload.category]: payload.removed ? filteredNotifications : [...filteredNotifications, payload] ? [...state.notifications[payload.category], payload] : state.notifications[payload.category]} : {...state.notifications, [payload.category]: [payload] }}

                return { ...state, notifications: { ...state.notifications, [payload.category]: payload.removed ? filteredNotifications : [...filteredNotifications, payload] } }
            }
            case actionTypes.SET_ZONES_WITH_STATUS: {
                return { ...state, zonesEnabled: action.payload }
            }
            default:
                return state;
        }
    }
)
