import { StrictMode } from "react";
import ReactDOM from 'react-dom';
import axios from 'axios';
import setupAxios from './utils/setupAxios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './redux/store';
import './index.css';
import './scss/custom.scss';
import './scss/pro-theme.scss';
import './scss/mini-theme.scss';
import './scss/pop-pro-theme.scss';
import './scss/micro-theme.scss';
import './scss/style.scss';
import './scss/responsive.scss';
import './scss/responsive_lower_third.scss';
import './scss/advance-register.scss';
import './scss/dev.scss';
import 'react-toastify/dist/ReactToastify.css';
import ServiceWorker from "./serviceWorker";
// import { app, appCheck } from './utils/firebase'
const { PUBLIC_URL } = process.env;

//setup axios 
setupAxios(axios, store);


ReactDOM.render(
  <StrictMode>
    <App store={store} persistor={persistor} basename={PUBLIC_URL} />
  </StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want to use your WebApp as PWA then just register it as ServiceWorker.register();
// or deregister with ServiceWorker.deRegister();

ServiceWorker.register();
