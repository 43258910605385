import { getAllDataByPath, getMessagesListWithListener, saveChat } from '../../utils/firebase';
import { actionTypes } from '../actionTypes';

export const getChatMessagesWithListener = (roomName, cb, limit) => async (dispatch) => {
    if (roomName) {
        // const path = `/${roomName}/messages`;
        const path = roomName;

        const saveChatMessagesList = (data) => {
            dispatch({
                type: actionTypes.GET_MESSAGES_LIST_ITEM,
                roomName,
                data
            });
            if (cb)
                cb(data)
        }
        getMessagesListWithListener(path, saveChatMessagesList, limit)
    }
}

export const hideChatNotification = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.HIDE_CHAT_NOTIFICATION,
        payload: value
    })
}

export const saveChatAction = (roomName, message, callback) => (dispatch) => {
    saveChat(roomName, message, callback);
}


// get all private chat notification
export const getAllPrivateChatNotification = (roomName, cb) => async (dispatch) => {
    if (roomName) {
        const path = roomName;
        let userList = [];
        let senderIds = [];
        getAllDataByPath(path, (data) => {
            if (data && data.id) {
                // console.log(data)
                for (const property in data) {
                    // console.log(property)
                    if (property && property !== "id") {
                        let roomData = data[property];
                        // console.log(roomData)
                        for (const prop in roomData) {
                            if (!senderIds.includes(roomData[prop].userId)) {
                                senderIds.push(roomData[prop].userId);
                                userList.push(roomData[prop]);
                            }
                        }

                    }
                }
            }
            dispatch({
                type: actionTypes.GET_ALL_PRIVATE_CHAT_NOTIFICATION_LIST,
                payload: { userList, roomName }
            });
            if (cb) {
                cb(data)
            }
        })
    }
}

export const togglePeopleChat = (payload) => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_PEOPLE_CHAT,
        payload
    });
}


export const updateChatList = (payload) => (dispatch) => {
    dispatch({
        type: actionTypes.CHAT_LIST,
        payload
    });
}


export const emojiIcon = (payload) => (dispatch) => {
    dispatch({
        type: actionTypes.EMOJI,
        payload
    });
}

export const fromWhichChat = (payload) => (dispatch) => {
    dispatch({
        type: actionTypes.FROM_WHICH_CHAT,
        payload
    });
}

export const hideStageChatNotification = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.HIDE_STAGE_CHAT,
        payload: value
    })
}

export const hideExpoChatNotification = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.HIDE_EXPO_CHAT,
        payload: value
    })
}

export const hideBackStageChatNotification = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.HIDE_BACKSTAGE_CHAT,
        payload: value
    })
}

export const togglePublicChat = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.TOGGLE_PUBLIC_CHAT,
        payload: value
    })
}

export const isPublicChatDeleted = (value) => (dispatch) => {
    dispatch({
        type: actionTypes.DELETE_PUBLIC_CHAT,
        payload: value
    })
}
