import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { actionTypes } from '../actionTypes';

const initialState = {
    list: [],
    chairList: [],
    enableNotification: false,
    notifyData: [],
    verifyData: [],
};

export default persistReducer({ storage, key: "webinar-events-rooms", whitelist: ["list"] },
    (state = initialState, action) => {
        const { type, payload, data } = action;
        switch (type) {
            case actionTypes.GET_ROOM_DETAILS_INITIATED: {
                return { ...state, loading: true };
            }
            case actionTypes.GET_ROOM_DETAILS_SUCCESS: {
                return { ...state, list: payload, loading: false };
            }
            case actionTypes.GET_ROOM_DETAILS_ERROR: {
                return { ...state, loading: false };
            }
            case actionTypes.GET_ROOM_CHAIR_DETAILS: {
                // const keys = Object.keys(data.data);
                let chairListData = [];
                Object.entries(data.data).map(([id, value]) => {
                    // eslint-disable-next-line no-unused-expressions
                    return Object.entries(value).map(([key, data]) => {
                        return chairListData.push({ key: key, id: id, data: data })
                    })
                })
                return { ...state, chairList: chairListData }
            }
            case actionTypes.GET_ROOM_CHAIR_DELETE_DETAILS: {
                let chairListData = [];
                Object.entries(data.data).map(([id, value]) => {
                    // eslint-disable-next-line no-unused-expressions
                    return Object.entries(value).map(([key, data]) => {
                        return chairListData.push({ key: key, id: id, data: data })
                    })
                })
                return { ...state, chairList: chairListData };
            }

            case actionTypes.ENABLE_ADD_NOTIFICATION: {
                return { ...state, enableNotification: payload };
            }

            case actionTypes.NOTIFICATION_DATA: {
                return { ...state, notifyData: payload.notifyData, verifyData: payload.verifyData };
            }

            default:
                return state;
        }
    })